
import React from "react"
import "./style/nav.css"
import "./style/cobertura.css"
import "./style/formulario.css"
import "./style/inicio.css"
import "./style/card.css"
import "./style/structure.css"
import "./style/evaluacion.css"
import "./style/gallery.css"
import "./style/footer.css"
import Nav from './components/navbar'
import Inicio from './components/inicio'
import Cobertura from "./components/cobertura";
import Form from "./components/form";
import HolderCards from "./components/cards/holderCard"
import Evaluacion from "./components/evaluacion"
import Galler from "./components/gallery/gallery"
import Footer from "./components/footer/footer"
import Card from "./components/cards/card"

import informesvg from "./img/cards/informe.svg"
import factibilidad from "./img/cards/factibilidad.svg"

const App = () => {
  return (
    <div>
      <section id="in">
        <Inicio></Inicio>
      </section>
      <Nav></Nav>
      <section id="ser">
        <HolderCards></HolderCards>
        <Evaluacion></Evaluacion>
        <div id="column-box2">
        <div id="container-seconds-cards">
                
                <div id="services-proyect">
                    <Card icon={informesvg}  type="medium" title="Informes de Avance" text="Seguimiento y control de obras, mediante informes de avance."></Card>
                    <Card icon={factibilidad}  type="medium" title="Factibilidad Técnica" text="Desarrollo de evaluaciones para el Sector Inmobiliario, Industrial e Infraestructura en general."></Card>
                </div>
                </div>
         </div>
      </section>
      <section id="cob">
        <Cobertura></Cobertura>
      </section>
      
      <Galler></Galler>
      {/* <section id="cont">
        <Form></Form>
      </section> */}

      <Footer></Footer>
    </div>
  )
};

export default App;

