import React from "react"
import b1 from "../../img/galleryimg/1.jpg"
import b2 from "../../img/galleryimg/2.jpg"
import b3 from "../../img/galleryimg/3.jpg"
import b4 from "../../img/galleryimg/4.jpg"
import b5 from "../../img/galleryimg/5.jpg"
import b6 from "../../img/galleryimg/6.jpg"
import b7 from "../../img/galleryimg/7.jpg"
import b8 from "../../img/galleryimg/8.jpg"
import b9 from "../../img/galleryimg/9.PNG"
import b10 from "../../img/galleryimg/10.PNG"
import b11 from "../../img/galleryimg/11.PNG"
import b12 from "../../img/galleryimg/12.PNG"

const Galler = (props) => {
  return (
    <div className="gallery-container">
      <img src={b1} alt="" />
      <img src={b2} alt="" />
      <img src={b3} alt="" />
      <img src={b4} alt="" />
      <img src={b5} alt="" />
      <img src={b6} alt="" />
      <img src={b7} alt="" />
      <img src={b8} alt="" />
      <img src={b11} alt="" />
      <img src={b12} alt="" />
    </div>
  )
};

export default Galler;
