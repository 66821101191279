
import React, { useState, useEffect, useRef } from "react";
import sennLogo2 from "../img/sennLogo2.svg"
import b1 from "../img/backgrounds/b1.png"
import b2 from "../img/backgrounds/b2.png"
import b3 from "../img/backgrounds/b3.png"
import b4 from "../img/backgrounds/b4.png"
import b5 from "../img/backgrounds/b5.png"


export const Inicio = () => {
    
    const images = [b1,b2,b3,b4,b5];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imageRef = useRef(null);
  
    // Función para precargar imágenes
    const preloadImages = (urls) => {
      const promises = urls.map((url) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
          img.onerror = reject;
        });
      });
  
      return Promise.all(promises);
    };
  
    useEffect(() => {
      // Precargar todas las imágenes antes de iniciar el ciclo
      preloadImages(images)
        .then(() => setImagesLoaded(true))
        .catch((err) => console.error("Error al cargar las imágenes", err));
    }, [images]);
  
    useEffect(() => {
      if (imagesLoaded) {
        const interval = setInterval(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5500);
  
        return () => clearInterval(interval);
      }
    }, [imagesLoaded, images.length]);
  
    if (!imagesLoaded) {
      return <div>Cargando imágenes...</div>;
    }
    
    return (
        <div className="desc-container" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
            <div className="desc">
                <img src={sennLogo2} alt="logo-grande"></img>
                <h1>Servicios de Tasaciones y Consultoría</h1>
                <p> Senn Consultores Ltda, es una empresa constituida para presentar servicios de
                    Tasaciones y Consultoría en General. Somos especialistas en el área de valorización de
                    bienes de alta complejidad y nuestra fortaleza se basa en la trayectoria en el Sector
                    Bancario y de Ingeniería en Consulta, donde se han desarrollado sobre 20.000
                    tasaciones y participado en la aprobación de más de 80.000 tasaciones de diversos
                    tipos de bienes.
                </p>
                <div className="button-container"><a href="#cont">Contacto</a></div>
            </div>
        </div>
    )
    };

export default Inicio;
